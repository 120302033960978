































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component
export default class ConfirmDialog extends Vue {
    @Prop({ default: ''})
    private title!: string;

    @Prop()
    private msg!: string;

    @Prop({ default: ''})
    private confirmingText!: string;

    @Prop()
    private object!: any;

    @Prop({ default: 'red' })
    private color!: string;

    @Prop({ default: false })
    private open!: boolean;

    confirmedText: string|null = null;

    @Watch('open')
    watchOpen() {
        this.confirmedText = null
    }

    confirmed() {
        this.$emit('confirm-modal:confirmed', this.object);
    }

    close() {
        this.$emit('confirm-modal:close');
    }
}
